import { StyledButtonConatiner, StyledContainer, StyledContent, StyledLang, StyledLangContainer, StyledLogo, StyledPhone } from "./gallery-top-line-styled";

import { Button } from "../../../../components";
import Menu from "./components/menu";
import React from "react";
import logo from "./images/logo.png";

// import menu from "./images/menu.png";

const GalleryTopLineView = ({ className }) => {
  return (
    <StyledContainer className={className}>
      <StyledLogo src={logo} />

      <StyledContent>
        <StyledPhone>+38 (044) 355-06-05</StyledPhone>

        <StyledLangContainer>
          <StyledLang>RU</StyledLang>
        </StyledLangContainer>

        <StyledButtonConatiner>
          <Button title="Просчитать" size="big" />
        </StyledButtonConatiner>

        <Menu />
      </StyledContent>
    </StyledContainer>
  );
};

export default GalleryTopLineView;
