import React, { createRef, useEffect } from "react";
import {
  StyledContainer,
  StyledContent,
  StyledHeader
} from "./section-styled";

import { SectionHeader } from "../../components";
import background from "./images/background.png";

const Section = ({ title, description, children }) => {
  return (
    <StyledContainer image={background}>
      {title && description &&
        <StyledHeader>
          <SectionHeader title={title} description={description} theme="dark" />
        </StyledHeader>
      }

      <StyledContent>
        {children}
      </StyledContent>
    </StyledContainer>
  );
};

export default Section;
