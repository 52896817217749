import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`

  /* padding: 0 ${responsiveSize(163)}; */

  /* margin-bottom: ${responsiveSize(60)}; */

  display: flex;
  justify-content: space-between;
`;

export const StyledTitle = styled.h2`
  margin-bottom: ${responsiveSize(22)};

  font-size: ${responsiveSize(48)};
  line-height: 129%;
  font-weight: 400;
  font-style: italic;

  color: ${props => (props.theme == "white" ? "#020511" : "#FFFFFF")};

  @media (max-width: 575.98px) {
    margin-bottom: ${responsiveSize(15)};

    font-size: ${responsiveSize(26)};
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    margin-bottom: ${responsiveSize(15)};

    font-size: ${responsiveSize(26)};
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    margin-bottom: ${responsiveSize(18)};

    font-size: ${responsiveSize(36)};
  }
`;

export const StyledDescription = styled.p`
  width: ${props => `${props.widthDescription}%`};

  font-size: ${responsiveSize(18)};
  line-height: ${responsiveSize(38)};

  color: ${props => (props.theme == "white" ? "#020511" : "#FFFFFF")};

  @media (max-width: 575.98px) {
    font-size: ${responsiveSize(14)};
    line-height: ${responsiveSize(26)};
     
  }

  @media (max-width: 767.98px) {
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    font-size: ${responsiveSize(14)};
    line-height: ${responsiveSize(26)};
   
    /* width: ${props => `${props.widthDescription + 5}%`}; */
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    font-size: ${responsiveSize(16)};
    line-height: ${responsiveSize(34)};
    width: ${props => `${props.widthDescription + 20}%`};
  }

  /* @media (max-width: 991.98px) {
    width: ${props => `${props.widthDescription}%`};
  } */

  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: ${props => `${props.widthDescription + 25}%`};
  }
`;
