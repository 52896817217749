import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  /* padding: ${responsiveSize(120)} 0; */

  padding-top: ${responsiveSize(120)};

  background: #F3F5F9;

  @media (max-width: 575.98px) {
    padding-top: ${responsiveSize(70)};
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    padding-top: ${responsiveSize(80)};
  }

  @media (min-width: 768px) and (max-width: 991.98px) { 
   padding-top: ${responsiveSize(90)};
  }

  @media (min-width: 992px) and (max-width: 1199.98px) { 
    padding-top: ${responsiveSize(100)};
  }
`;

export const StyledHeader = styled.div`
  padding: 0 ${responsiveSize(163)};

  display: flex;
  justify-content: space-between;

  @media (max-width: 575.98px) {
    padding: 0 ${responsiveSize(24)};
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    padding: 0 ${responsiveSize(48)};
  }

  @media (min-width: 768px) and (max-width: 1199.98px) {
    padding: 0 ${responsiveSize(80)};
  }
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledTitle = styled.h2`
  margin-bottom: ${responsiveSize(22)};

  font-weight: 500;
  font-size: ${responsiveSize(48)};

  color: #020511;
`;

export const StyledDescription = styled.p`
  font-size: ${responsiveSize(18)};
  line-height: ${responsiveSize(38)};
  max-width: 75%;

  color: #020511;
`;

export const StyledControl = styled.div`
  margin-right: ${responsiveSize(20)};

  display: flex;
  align-items: flex-end;

  @media (max-width: 991.98px) {
    display: none;
  }
`;

export const StyledArrow = styled.img`
  height: ${responsiveSize(15)};
  /* width: ${responsiveSize(54)}; */
  padding: ${responsiveSize(10)} 0px;
  object-fit: contain;

  cursor: pointer;

  transition: all 0.5s;

  opacity: ${props => (props.disabled ? 0.3 : 1)};

  /* transform: scale(${props => (props.disabled ? 0.5 : 1)}); */

  &:hover {
   opacity: ${props => (props.disabled ? 0.3 : 0.5)};
  }
`;

export const StyledCircle = styled.img`
  height: ${responsiveSize(5)};
  width: ${responsiveSize(5)};

  padding: ${responsiveSize(10)} ${responsiveSize(23)};
  margin-bottom: ${responsiveSize(5)};
  /* margin: auto; */
`;

export const StyledContent = styled.div`
  /* padding: 0 ${responsiveSize(163)}; */

  /* width: 100%; */

  display: flex;
`;
