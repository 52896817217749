import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  /* height: ${props => responsiveSize(props.options[props.size].height)}; */
  height: ${responsiveSize(44)};
  width: ${props => (props.full ? "100%" : "auto")};

  

  padding: 0 ${responsiveSize(32)};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${props =>
    props.round ? "transparent" : props.backgroundColor};
  /* border: 1px solid ${props => props.backgroundColor}; */
  border-radius: ${props =>
    responsiveSize(props.options[props.size].borderRadius)};

  cursor: pointer;

  transition: all 0.5s;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 575.98px) {
    height: ${responsiveSize(32)};
  }
`;

export const StyledContent = styled.div`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${props => (props.loading ? 0 : 1)};
`;

export const StyledLoader = styled.div`
  /* width: 100%;
  height: 100%; */

  flex-direction: row;

  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: 1;
`;

export const StyledText = styled.p`
  font-weight: 500;
  /* font-size: ${props =>
    responsiveSize(props.options[props.size].fontSize)}; */

  font-size: ${props => responsiveSize(14)};

  line-height: 1;

  text-align: center;
  text-transform: uppercase;

  color: ${props => props.titleColor};

  @media (max-width: 575.98px) {
    font-size: ${props => responsiveSize(12)};
  }
`;
