import Card from "./components/card";
import React from "react";
import { Section } from "../../../../components";
import image1 from "./images/1.png";
import image2 from "./images/2.png";
import image3 from "./images/3.png";

const items = [
  {
    styleName: "Дизайн проект",
    name: "Адаптируем стили под вашу квартиру за 7 дней.",
    image: image1
  },
  {
    styleName: "Сервис",
    name: "Мы превращаем ремонт в радость, избавляя вас от хлопот.",
    image: image2
  },
  {
    styleName: "Материалы без мебели",
    name: "Мы делаем около 100 ремонтов в год. Наши объемы закупок позволяют снизить стоимость для вас.",
    image: image3
  }
];

const IncludedPriceView = () => {
  return (
    <Section
      title={
        <span>
          <span
            style={{
              fontWeight: "700",

              fontStyle: "normal"
            }}
          >
            Входит
          </span>{" "}
          <span style={{ fontFamily: "Playfair Display", fontWeight: "400" }}>
            в стоимость
          </span>
        </span>
      }
      description="В наши услуги входят перечисленные опции, все очень просто."
    >
      {/* <div>
        <Card {...items[0]} />

        <Card {...items[1]} />

        <Card {...items[2]} />
      </div> */}
    </Section>
  );
};

export default IncludedPriceView;
