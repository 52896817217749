import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
  width: ${responsiveSize(887)};
  height: ${responsiveSize(465)};
  margin-right: ${responsiveSize(57)};

  box-sizing: border-box;

  background-image: url(${props => props.image});
  background-size: cover;

  border-radius: ${responsiveSize(24)};

  @media (max-width: 767.98px) {
    margin-right: ${responsiveSize(16)};
    width: ${responsiveSize(311)};
    height: ${responsiveSize(293)};
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    width: ${responsiveSize(311 * 1.4)};
    height: ${responsiveSize(293 * 1.4)};
    margin-right: ${responsiveSize(40)};
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: ${responsiveSize(887 * 0.8)};
    height: ${responsiveSize(465 * 0.8)};
  }
`;

export const StyledContent = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding: ${responsiveSize(40)};

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  z-index: 1;

  @media (max-width: 767.98px) {
    padding: ${responsiveSize(24)};
  }

  @media (max-width: 991.98px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledInfo = styled.div``;

export const StyledStyle = styled.p`
  margin-bottom: ${responsiveSize(8)};

  font-weight: 500;
  font-size: ${responsiveSize(14)};
  line-height: ${responsiveSize(16)};

  color: #fff;
  opacity: 0.57;

  @media (max-width: 767.98px) {
    font-size: ${responsiveSize(14)};
    line-height: ${responsiveSize(20)};
  }
`;

export const StyledName = styled.p`
  width: ${responsiveSize(190)};
  font-weight: 600;
  font-size: ${responsiveSize(21)};
  line-height: ${responsiveSize(32)};

  color: #fff;

  @media (max-width: 767.98px) {
    width: 80%;

    font-size: ${responsiveSize(22)};
    line-height: ${responsiveSize(34)};
  }
`;

export const StyledControl = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 991.98px) {
    width: 100%;

    justify-content: space-between;
  }
`;

export const StyledButton = styled.div.attrs({
  styleText: {
    fontWeight: "600"
  }
})``;

export const StyledArrow = styled.img`
  width: ${responsiveSize(40)};
  height: ${responsiveSize(40)};

  margin-left: ${responsiveSize(15)};

  cursor: pointer;

  transition: all 0.5s;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 767.98px) {
    width: ${responsiveSize(32)};
    height: ${responsiveSize(32)};
  }
`;

export const StyledShadow = styled.div`
  position: absolute;
  height: ${responsiveSize(228)};
  left: 0px;
  right: 0px;
  bottom: 0px;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.8;
  border-radius: ${responsiveSize(20)};

  @media (max-width: 767.98px) {
    height: 100%;

    background: #000000;

    opacity: 0.55;
  }

  &::after {
    content: "";

    position: absolute;
    left: ${responsiveSize(39)};
    right: ${responsiveSize(45)};
    top: ${responsiveSize(70)};
    bottom: ${responsiveSize(-26)};

    background: linear-gradient(0deg, #000000, #000000);
    opacity: 0.5;

    filter: blur(${responsiveSize(30)});
    border-radius: ${responsiveSize(24)};

    z-index: -2;

    @media (max-width: 767.98px) {
      left: 4.5%;
      right: 5.14%;
      top: 15.7%;
      bottom: -5.8%;

      background: linear-gradient(0deg, #000000, #000000);
      /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)); */

      filter: blur(${responsiveSize(15)});
    }
  }
`;
