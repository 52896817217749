import HomeHowWork from "./components/how-work";
import HomeMenu from "./components/menu";
import HomeOurWork from "./components/our-work";
import HomeTopLine from "./components/top-line";
import IncludedPrice from "./components/included-price";
import React from "react";
import { StyledContainer } from "./home-styled";

import { responsiveSize } from "../../utils/font";

const HomeView = () => {
  return (
    <StyledContainer>
      <HomeTopLine />

      <div style={{ height: responsiveSize(45) }}></div>

      <HomeHowWork />

      <IncludedPrice />

      <HomeOurWork />

      {/* <p style={{ fontSize: "1em" }}>Hello</p> */}

      {/* <HomeMenu /> */}
    </StyledContainer>
  );
};

export default HomeView;
