import Card from "./components/card";
import React from "react";
import { SectionSlides } from "../../../../components";
import { StyledContainer } from "./how-work-styled";
import image_01 from "./images/01.svg";
import image_02 from "./images/02.svg";
import image_03 from "./images/03.svg";
import image_04 from "./images/04.svg";
import image_05 from "./images/05.svg";

const items = [
  {
    image: image_01,
    name: "Обсуждаем проект",
    description:
      "Первым этапом идет утверждение дизайна и обсуждение всех деталей проекта."
  },
  {
    image: image_02,
    name: "Выезжаем на объект",
    description:
      "Бригада профессионалов выдвигается в ваше помещение для замеров и оценки объема работы."
  },
  {
    image: image_03,
    name: "Начинаем работу",
    description:
      "Начинается старт работ. В этот этап рабочие начинают реализовывать задуманное."
  },
  {
    image: image_04,
    name: "Выезжаем на объект",
    description:
      "Бригада профессионалов выдвигается в ваше помещение для замеров и оценки объема работы."
  },
  {
    image: image_05,
    name: "Начинаем работу",
    description:
      "Начинается старт работ. В этот этап рабочие начинают реализовывать задуманное."
  }
];

const HowWorkView = () => {
  const renderItem = item => <Card {...item} />;

  return (
    <StyledContainer>
      <SectionSlides
        title={
          <span>
            <span
              style={{
                fontWeight: "700",
                fontStyle: "normal"
              }}
            >
              Как мы
            </span>{" "}
            <span style={{ fontFamily: "Playfair Display", fontWeight: "400" }}>
              работаем
            </span>
          </span>
        }
        description="Наш процесс работы происходит в несколько этапов. Ниже перечислены шаги разработки вашего ремонта."
        items={items}
        renderItem={renderItem}
        widthDescription={55}
      />
    </StyledContainer>
  );
};

export default HowWorkView;
