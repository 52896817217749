import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Home from "../domains/home";
// import NoMatch from "../domains/no-match";
import React from "react";

export default () => (
  <Router>
    <Switch>
     
      {/* <Route exact path="/">
        <Editor />
      </Route> */}

      <Route path="*">
        <Home />
      </Route>
    </Switch>
  </Router>
);
