import { responsiveSize } from "../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  height: ${responsiveSize(115)};
  /* width: 100%; */

  padding: 0 ${responsiveSize(56)} 0 ${responsiveSize(64)};

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  left: 0;
  right: 0;

  z-index: 5;

  background: #020511;

  @media (max-width: 575.98px) {
    height: ${responsiveSize(56)};

    padding: 0 ${responsiveSize(24)} 0 ${responsiveSize(16)};
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    height: ${responsiveSize(66)};

    padding: 0 ${responsiveSize(30)} 0 ${responsiveSize(22)};
  }

  @media (min-width: 768px) and (max-width: 991.98px) { 
    height: ${responsiveSize(80)};

    padding: 0 ${responsiveSize(36)} 0 ${responsiveSize(44)};
  }

  @media (min-width: 992px) and (max-width: 1199.98px) { 
    height: ${responsiveSize(100)};

    padding: 0 ${responsiveSize(46)} 0 ${responsiveSize(54)};
  }
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledLogo = styled.img`
  width: ${responsiveSize(120)};

  @media (max-width: 575.98px) {
    width: ${responsiveSize(70)};
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    width: ${responsiveSize(75)};
  }

  @media (min-width: 768px) and (max-width: 991.98px) { 
   width: ${responsiveSize(90)};
  }
`;

export const StyledPhone = styled.p`
  margin-right: ${responsiveSize(40)};

  color: #ffffff;
  font-weight: 600;
  font-size: ${responsiveSize(16)};

  @media (max-width: 991.98px) {
    display: none;
  }

  /* @media (min-width: 768px) and (max-width: 991.98px) { ... } */
`;

export const StyledLangContainer = styled.div`
  width: ${responsiveSize(51)};
  height: ${responsiveSize(51)};

  margin-right: ${responsiveSize(16)};

  display: flex;
  justify-content: center;
  align-items: center;

  background: #1c1f2a;
  border-radius: ${responsiveSize(8)};

  cursor: pointer;

  @media (max-width: 767.98px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991.98px) { 
    width: ${responsiveSize(41)};
    height: ${responsiveSize(41)};
  }
`;

export const StyledLang = styled.p`
  font-weight: 600;
  font-size: ${responsiveSize(16)};

  color: #ffffff;

  @media (min-width: 768px) and (max-width: 991.98px) { 
    font-size: ${responsiveSize(14)};
  }
`;


export const StyledButtonConatiner = styled.div`
  @media (max-width: 767.98px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991.98px) { 
    transform: scale(0.8)
  }
`;
