import React from "react";

import SectionSlidesCarouselView from "./section-slides-carousel-view";

const SectionSlidesCarouselContainer = ({
  items,
  activeIndex,
  onChangeActiveIndex,
  ...props
}) => {
  const handlerSwipe = direct => {
    if (direct === "right") {
      onChangeActiveIndex(activeIndex - 1);
    } else {
      onChangeActiveIndex(activeIndex + 1);
    }
  };

  return (
    <SectionSlidesCarouselView
      {...props}
      items={items}
      activeIndex={activeIndex}
      onSwipe={handlerSwipe}
    />
  );
};

export default SectionSlidesCarouselContainer;
