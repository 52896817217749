import React, { useState } from "react";

import SectionSlidesView from "./section-slides-view";

const SectionSlidesContainer = ({ items, ...props }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlerChangeActiveIndex = index => {
    if (index >= 0 && index < items.length) setActiveIndex(index);
  };

  return (
    <SectionSlidesView
      {...props}
      items={items}
      activeIndex={activeIndex}
      onChangeActiveIndex={handlerChangeActiveIndex}
    />
  );
};

export default SectionSlidesContainer;
