import { StyledContainer, StyledContent, StyledImage } from "./home-top-line-menu-styled";

import React from "react";
import close from "./images/close.png";
import menu from "./images/menu.png";

const HomeTopLineMenuView = ({}) => {
  return (
    <StyledContainer>
      <StyledImage src={menu} />

      {/* <StyledContent>
        <p>ddd</p>
      </StyledContent> */}

      {/* <StyledImage src={close} /> */}
    </StyledContainer>
  );
};

export default HomeTopLineMenuView;
