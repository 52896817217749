import React, { useRef, useEffect } from "react";

import Slider from "react-slick";

import { StyledSlider } from "./section-slides-carousel-styled";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true
};

const SectionSlidesCarouselView = ({
  items,
  renderItem,
  activeIndex,
  OtherComponent,
  onSwipe
}) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    sliderRef.current.slickGoTo(activeIndex);
  }, [activeIndex]);

  return (
    <div style={{ width: "100%" }}>
      <StyledSlider as={Slider} {...settings} ref={sliderRef} onSwipe={onSwipe}>
        {items.map(renderItem)}

        {OtherComponent}
      </StyledSlider>
    </div>
  );
};

export default SectionSlidesCarouselView;
