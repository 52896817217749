import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  max-width: ${responsiveSize(370)};

  margin-right: ${responsiveSize(144)};

  /* margin-top: ${responsiveSize(28)}; */

  @media (max-width: 767.98px) {
    max-width: ${responsiveSize(288)};
    margin-right: ${responsiveSize(15)};
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    margin-right: ${responsiveSize(0)};
  }

  
`;

// export const StyledText = styled.span`
//   font-family: Inter;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 172px;
//   line-height: 172px;
//   /* identical to box height, or 100% */

//   font-feature-settings: "pnum" on, "lnum" on, "ss02" on;

//   border: 1.5px solid #c9c8cf;
// `;

export const StyledImage = styled.img`
  width: ${responsiveSize(319)};

  margin-bottom: ${responsiveSize(8)};

  @media (max-width: 767.98px) {
    width: ${responsiveSize(188)};
    /* height: 172px; */
    margin-bottom: ${responsiveSize(0)};
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    width: ${responsiveSize(220)};
  }
`;

export const StyledName = styled.p`
  margin-bottom: ${responsiveSize(14)};

  font-weight: 600;
  font-size: ${responsiveSize(24)};
  line-height: ${responsiveSize(38)};

  color: #020511;

  @media (max-width: 767.98px) {
    margin-bottom: ${responsiveSize(8)};

    font-size: ${responsiveSize(18)};
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    font-size: ${responsiveSize(20)};
  }
`;

export const StyledDescription = styled.p`
  width: 90%;

  font-size: ${responsiveSize(16)};
  line-height: ${responsiveSize(30)};

  color: #020511;

  @media (max-width: 767.98px) {
    font-size: ${responsiveSize(14)};
    line-height: ${responsiveSize(26)};
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    font-size: ${responsiveSize(15)};
  }
`;
