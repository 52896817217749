import React, { createRef, useEffect } from "react";
import {
  StyledContainer,
  StyledDescription,
  StyledTitle
} from "./section-header-styled";

const SectionHeader = ({ title, description, theme = "white", widthDescription = 100 }) => {
  return (
    <StyledContainer>
      <StyledDescription>
        <StyledTitle theme={theme}>{title}</StyledTitle>

        <StyledDescription theme={theme} widthDescription={widthDescription}>
          {description}
        </StyledDescription>
      </StyledDescription>
    </StyledContainer>
  );
};

export default SectionHeader;
