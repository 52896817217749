import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
  width: ${responsiveSize(887)};
  height: ${responsiveSize(465)};

  box-sizing: border-box;
  /* background-image: url(${props => props.image});
  background-position: center; */
  /* background-size: cover; */
  background-color: #e98a45;

  border-radius: 24px;
`;

export const StyledContent = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding: ${responsiveSize(40)};

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  z-index: 1;
`;

export const StyledTexture = styled.img`
  position: absolute;
  /* left: 153.1%;
  right: -259.08%;
  top: -146.45%; */
  /* top: 0; */
  top: -146.45%;
  right: -259.08%;

  /* bottom: -146.67%; */
  width: 1827px;
  height: 1828px;

  /* background: linear-gradient(
    0.75deg,
    rgba(255, 255, 255, 0) 25.38%,
    #ffffff 50.44%,
    rgba(255, 255, 255, 0) 74.98%
  ); */

  z-index: 7;
  mix-blend-mode: normal;
  /* opacity: 0.15; */
  transform: rotate(90deg);
`;

export const StyledArrow = styled.img`
  width: ${responsiveSize(40)};
  height: ${responsiveSize(40)};

  margin-left: auto;

  cursor: pointer;

  transition: all 0.5s;

  &:hover {
    opacity: 0.6;
  }
`;

export const StyledShadow = styled.div`
  position: absolute;
  height: ${responsiveSize(228)};
  left: 0px;
  right: 0px;
  bottom: 0px;

  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.8; */
  border-radius: ${responsiveSize(20)};

  &::after {
    content: "";

    position: absolute;
    left: ${responsiveSize(39)};
    right: ${responsiveSize(45)};
    top: ${responsiveSize(70)};
    bottom: ${responsiveSize(-26)};

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
      #e98a45;
    opacity: 0.5;

    filter: blur(${responsiveSize(30)});
    border-radius: ${responsiveSize(24)};

    z-index: -2;
  }
`;
