import React, { createRef, useEffect } from "react";
import {
  StyledArrow,
  StyledCircle,
  StyledContainer,
  StyledContent,
  StyledControl,
  StyledDescription,
  StyledHeader,
  StyledInfo,
  StyledTitle
} from "./section-slides-styled";

import { SectionHeader } from "../../components";
import SectionSlidesCarousel from "./carousel";
import circle from "./images/circle.png";
import left from "./images/left.svg";
import rigth from "./images/right.svg";

const SectionSlides = ({
  items,
  renderItem,
  title,
  description,
  widthDescription,
  activeIndex,
  OtherComponent,
  onChangeActiveIndex
}) => {
  return (
    <StyledContainer>
      <StyledHeader>
        <SectionHeader
          title={title}
          description={description}
          widthDescription={widthDescription}
        />

        <StyledControl>
          <StyledArrow
            src={left}
            disabled={activeIndex <= 0}
            onClick={() => onChangeActiveIndex(activeIndex - 1)}
          />

          <StyledCircle src={circle} />

          <StyledArrow
            src={rigth}
            disabled={activeIndex >= items.length - 1}
            onClick={() => onChangeActiveIndex(activeIndex + 1)}
          />
        </StyledControl>
      </StyledHeader>

      <StyledContent>
        {/* {activeIndex} */}
        <SectionSlidesCarousel
          items={items}
          renderItem={renderItem}
          activeIndex={activeIndex}
          OtherComponent={OtherComponent}
          onChangeActiveIndex={onChangeActiveIndex}
        />
      </StyledContent>
    </StyledContainer>
  );
};

export default SectionSlides;
