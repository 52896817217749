import React from "react";

import { SectionSlides } from "../../../../components";

import Card from "./components/card";
import CardOther from "./components/card-other";

import { StyledContainer } from "./our-work-styled";

import image1 from "./images/1.png";
import image2 from "./images/2.png";

const items = [
  {
    styleName: "Берлин",
    name: "ЖК «Французкий квартал» №13",
    image: image1
  },
  {
    styleName: "Нью-Йорк",
    name: "ЖК «Парковые озера 75»",
    image: image2
  }
];

const OurWorkView = () => {
  const renderItem = item => <Card {...item} />;

  return (
    <SectionSlides
      title={
        <span>
          <span
            style={{
              fontWeight: "700",
              fontStyle: "normal"
            }}
          >
            Наши
          </span>{" "}
          <span style={{ fontFamily: "Playfair Display", fontWeight: "400" }}>
            работы
          </span>
        </span>
      }
      description="Каждый мы создаем сотни проектов! Здесь мы подобрали самые свежие и стильные работы. Оцените сами."
      items={items}
      renderItem={renderItem}
      widthDescription={65}
      // OtherComponent={<CardOther />}
    />
  );
};

export default OurWorkView;
