// import { Select } from "../../../../components";
import {
  StyledContainer,
  StyledDescription,
  StyledImage,
  StyledName,
  StyledText
} from "./our-work-card-styled";

import { Button } from "../../../../../../components";
import React from "react";
import arrow from "./images/arrow.png";

const options = [{ value: "last-created", label: "Last Created" }];

const GalleryHeaderView = ({ image, name, description }) => {
  return (
    <StyledContainer>
      <StyledImage src={image} />
      {/* <StyledText>01</StyledText> */}

      <StyledName>{name}</StyledName>

      <StyledDescription>{description}</StyledDescription>
    </StyledContainer>
  );
};

export default GalleryHeaderView;
