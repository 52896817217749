import React from "react";

import { Button } from "../../../../../../components";

import {
  StyledArrow,
  StyledButton,
  StyledContainer,
  StyledContent,
  StyledControl,
  StyledInfo,
  StyledName,
  StyledStyle,
  StyledShadow
} from "./our-work-card-styled";

import arrow from "./images/arrow.png";

const GalleryHeaderView = ({ styleName, name, image }) => {
  return (
    <StyledContainer image={image}>
      <StyledContent>
        <StyledInfo>
          <StyledStyle>Стиль «{styleName}»</StyledStyle>

          <StyledName>{name}</StyledName>
        </StyledInfo>

        <StyledControl>
          <StyledButton as={Button} title="Хочу так же!" size="large" />

          <StyledArrow src={arrow} />
        </StyledControl>
      </StyledContent>

      <StyledShadow />
    </StyledContainer>
  );
};

export default GalleryHeaderView;
