import styled from "styled-components";

import { responsiveSize } from "../../../utils/font";

export const StyledSlider = styled.div`
  width: 100%;

  & .slick-list {
    padding-left: ${responsiveSize(163)};
    padding-bottom: ${responsiveSize(120)};
    padding-top: ${responsiveSize(60)};

    @media (max-width: 575.98px) {
      padding-left:${responsiveSize(24)};
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
      padding-left: ${responsiveSize(48)};
    }

    @media (min-width: 768px) and (max-width: 1199.98px) {
      padding-left: ${responsiveSize(80)};
    }

     @media (max-width: 767.98px) {
      padding-top: ${responsiveSize(24)};
      padding-bottom: ${responsiveSize(70)};
    }
/* 
    @media (max-width: 575.98px) {
      padding-left: ${responsiveSize(24)};
      
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
      padding-left: ${responsiveSize(48)};
    }

    @media (max-width: 767.98px) {
      padding-top: ${responsiveSize(24)};
      padding-bottom: ${responsiveSize(70)};
    }

    @media (min-width: 768px) and (max-width: 1199.98px) {
      padding-left: ${responsiveSize(80)};
    } */

  }

  & .slick-slide {
    /* margin-right: ${responsiveSize(57)}; */
  }
`;
