import { responsiveSize } from "../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: ${responsiveSize(120)} ${responsiveSize(163)};

  background: #020511;

  background-image: url(${(props) => props.image});
  background-size: cover;

  @media (max-width: 575.98px) {
    padding: ${responsiveSize(70)} ${responsiveSize(24)};
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    padding: ${responsiveSize(80)} ${responsiveSize(48)};
  }

  @media (min-width: 768px) and (max-width: 991.98px) { 
   padding: ${responsiveSize(90)} ${responsiveSize(90)};
  }

  @media (min-width: 992px) and (max-width: 1199.98px) { 
    padding: ${responsiveSize(100)} ${responsiveSize(120)};
  }
`;

export const StyledHeader = styled.div`
  /* width: 100%; */

  /* padding: 0 ${responsiveSize(163)}; */

  margin-bottom: ${responsiveSize(40)};

  display: flex;
  justify-content: space-between;

  /* @media (max-width: 575.98px) {
    padding: 0 ${responsiveSize(24)};
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    padding: 0 ${responsiveSize(48)};
  }

  @media (min-width: 768px) and (max-width: 991.98px) { 
   padding: 0 ${responsiveSize(90)};
  }

  @media (min-width: 992px) and (max-width: 1199.98px) { 
    padding: 0 ${responsiveSize(120)};
  } */
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledTitle = styled.h2`
  font-weight: 500;
  font-size: ${responsiveSize(48)};

  color: #FFFFFF;
`;

export const StyledDescription = styled.p`
  font-size: ${responsiveSize(18)};
  line-height: ${responsiveSize(38)};

  color: #FFFFFF;
`;

export const StyledContent = styled.div`
  display: flex;
`;
