import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  margin-left: ${responsiveSize(24)};
`;

export const StyledImage = styled.img`
  width: ${responsiveSize(40)};
  height: ${responsiveSize(40)};

  cursor: pointer;

  transition: all 0.5s;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 575.98px) {
    width: ${responsiveSize(30)};
    height: ${responsiveSize(30)};
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    width: ${responsiveSize(32)};
    height: ${responsiveSize(32)};
  }

  @media (min-width: 768px) and (max-width: 991.98px) { 
    width: ${responsiveSize(35)};
    height: ${responsiveSize(35)};
  }
`;

// export const StyledContent = styled.div`
//   background-color: red;

//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
// `;
