import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import HomeVew from "./home-view";

const HomeContainer = (props) => {
  return (
    <HomeVew
      {...props}
    />
  );
};

export default HomeContainer;
