import { responsiveSize } from "../../../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
  width: ${responsiveSize(395)};
  height: ${responsiveSize(365)};
  margin-right: ${responsiveSize(57)};

  box-sizing: border-box;

  background-image: url(${props => props.image});
  background-size: cover;

  border-radius: ${responsiveSize(24)};

  @media (max-width: 575.98px) {
    margin-right: ${responsiveSize(16)};
    width: ${responsiveSize(311)};
    height: ${responsiveSize(293)};
  }
`;

export const StyledContent = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding: ${responsiveSize(40)};

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  z-index: 1;

  @media (max-width: 575.98px) {
    flex-direction: column;
    align-items: flex-start;
    padding: ${responsiveSize(24)};
  }
`;

export const StyledInfo = styled.div``;

export const StyledName = styled.p`
  margin-bottom: ${responsiveSize(14)};

  font-weight: 600;
  font-size: ${responsiveSize(24)};
  line-height: ${responsiveSize(38)};

  color: #fff;

  @media (max-width: 575.98px) {
    font-size: ${responsiveSize(22)};
  }
`;

export const StyledDescription = styled.p`
  /* width: ${responsiveSize(190)}; */
  font-weight: 400;
  font-size: ${responsiveSize(18)};
  line-height: ${responsiveSize(32)};

  color: #fff;

  @media (max-width: 575.98px) {
    /* width: 80%; */

    font-size: ${responsiveSize(14)};
    line-height: ${responsiveSize(26)};
  }
`;

export const StyledShadow = styled.div`
  position: absolute;
  height: ${responsiveSize(228)};
  left: 0px;
  right: 0px;
  bottom: 0px;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.8;
  border-radius: ${responsiveSize(20)};

  @media (max-width: 575.98px) {
    height: 100%;

    background: #000000;

    opacity: 0.55;
  }

  &::after {
    content: "";

    position: absolute;
    left: ${responsiveSize(39)};
    right: ${responsiveSize(45)};
    top: ${responsiveSize(70)};
    bottom: ${responsiveSize(-26)};

    background: linear-gradient(0deg, #000000, #000000);
    opacity: 0.5;

    filter: blur(${responsiveSize(30)});
    border-radius: ${responsiveSize(24)};

    z-index: -2;

    @media (max-width: 575.98px) {
      left: 4.5%;
      right: 5.14%;
      top: 15.7%;
      bottom: -5.8%;

      background: linear-gradient(0deg, #000000, #000000);
      /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)); */

      filter: blur(${responsiveSize(15)});
    }
  }
`;
