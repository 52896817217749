import { responsiveSize } from "../../../../utils/font";
import styled from "styled-components";

export const StyledContainer = styled.div`
  margin-top: ${responsiveSize(115)};

  /* width: 100vw;
  height: 100vh; */

  position: fixed;
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */

  background-color: #020511;
`;
